import React from "react";
import gkrinception from "../../assets/images/about/gkr-inception/gkr-inception-banner.png";

import inception1 from "../../assets/images/about/gkr-inception/gkr-inception1.png";

import inception2 from "../../assets/images/about/gkr-inception/gkr-inception2.png";

import inception3 from "../../assets/images/about/gkr-inception/gkr-inception3.png";

import inception4 from "../../assets/images/about/gkr-inception/gkr-inception4.png";

import inception5 from "../../assets/images/about/gkr-inception/gkr-inception5.png";

import inception6 from "../../assets/images/about/gkr-inception/gkr-inception6.png";

import inception7 from "../../assets/images/about/gkr-inception/gkr-inception7.png";


const Gkrinceptionpage = () => {
  return (
    <div className="gkrinception-wrapper subpages pad60" style={{paddingBottom: '0px !important'}}>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img src={gkrinception} className="img-fluid" alt="GKR" />
          </div>
          <div className="col-md-8">
            <h3 className="title-heading"> GGHR INCEPTION</h3>

            <p>
              {" "}
              "It was ideated that people come and run in the beautiful Kanha
              environment, and feel the place with their hearts. Simultaneously,
              there was a purpose in mind- to help Forests by Heartfulness
              initiative through this event.
            </p>
            <p>
              {" "}
              The 1st Edition happened on 26th Feb, 2023 and the 2nd edition is
              being planned on 19th Nov, 2023. Be a part of this magical running
              experience in the most magical place, the largest meditation
              center in the world and nurture oneness with self and with Mother
              Nature.{" "}
            </p>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            <img src={inception1} className="img-fluid" alt="GKR" />
          </div>
          <div className="col-md-3">
            <img src={inception2} className="img-fluid" alt="GKR" />
          </div>
          <div className="col-md-3">
            <img src={inception3} className="img-fluid" alt="GKR" />
          </div>
          <div className="col-md-3">
            <img src={inception4} className="img-fluid" alt="GKR" />
          </div>
         
        </div>
        <div className="row mt-5 mb-5">
        <div className="col-md-3">
            <img src={inception5} className="img-fluid" alt="GKR" />
          </div>
          <div className="col-md-3">
            <img src={inception6} className="img-fluid" alt="GKR" />
          </div>
          <div className="col-md-3">
            <img src={inception7} className="img-fluid" alt="GKR" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gkrinceptionpage;
