import React from "react";

import Layout from "../layout/index";

import Seo from "../components/seo/index";

import Gkrbreadcrumbpage from "../components/about/gkr-breadcrumb";

import Gkrinceptionpage  from "../components/about/gkrinception";

import Socialiconright from "../components/home/social-icon-right";

const Gkrinception = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024 - Kanha Shanthi Vanam" />
      <Layout isSticky>
        <Gkrbreadcrumbpage />
        <Socialiconright />
        <Gkrinceptionpage  />
      </Layout>
    </div>
  );
};

export default Gkrinception;
