import React from "react";

import gkrbreadcrumb from "../../assets/images/about/gkr-inception/gkr-breadcrumb.png";

export default function Gkrbreadcrumbpage() {
  return (
    <div className="breadcrumbsbanner-wrapper inner-pages">
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        <img
          className="img-fluid"
          src={gkrbreadcrumb}
          width="100%"
          alt="Clock logo"
        />
        <h1 className="breadcrumb-title"> GGHR INCEPTION </h1>
      </div>
    </div>
  );
}
